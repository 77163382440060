import { useNavigate } from '@remix-run/react';
import { Field, Form, Formik, type FormikValues } from 'formik';
import { useState } from 'react';

import { type DtoPublicOrganization } from '@lp-lib/api-service-client/public';
import { MediaFormatVersion } from '@lp-lib/media';

import { apiService } from '../../services/api-service';
import { type PublicOrganization } from '../../types';
import { type APIError } from '../../types/api';
import { fromDTOPublicOrganization } from '../../utils/api-dto';
import { getStaticAssetPath } from '../../utils/assets';
import { MediaUtils } from '../../utils/media';
import { LegalDisclaimer } from '../common/Utilities';
import { useLogoBrandColor } from '../VenueOrgLogoAverageColor/useLogoBrandColor';
import { buildSearchParamsWithRedirectToAsString, usePostLogin } from './hooks';
import { LoginHeader, LoginModalLayoutV2 } from './LoginModalLayout';

export const GuestLoginModal = (props: {
  conversion: boolean;
  setStep: (i: number) => void;
  initialFirstName?: Nullable<string>;
}): JSX.Element => {
  const { conversion, setStep, initialFirstName } = props;
  const [error, setError] = useState<APIError | null>(null);
  const postLogin = usePostLogin();
  const navigate = useNavigate();

  const validate = (values: { username: string }) => {
    const errors: { username?: string } = {};
    if (!values.username) {
      errors.username = 'Username is required';
    } else if (values.username.length < 3) {
      errors.username = 'Username must be at least 3 characters';
    } else if (values.username.length > 25) {
      errors.username = 'Max character count reached';
    }

    return errors;
  };

  const onSubmit = async (values: FormikValues, actions: FormikValues) => {
    try {
      const resp = await apiService.auth.guestLogin({
        username: values.username,
      });
      postLogin(resp.data);
    } catch (error: UnassertedUnknown) {
      setError(error);
    }
    actions.setSubmitting(false);
    setStep(1);
  };

  const handleLoginWithPassword = () => {
    navigate({
      pathname: '/login',
      search: buildSearchParamsWithRedirectToAsString(window.location.href, {
        'login-type': 'password',
      }),
    });
  };

  return (
    <LoginModalLayoutV2>
      <div className='relative text-white transform translate-y-[10%] w-full flex justify-center'>
        <LoginHeader />
        <div
          className={`w-max h-full flex flex-col items-center bg-black
    bg-opacity-80 border border-secondary rounded-2.25xl 
    pt-6 pb-6 px-5 lg:px-15 ${conversion ? 'gap-3' : 'gap-5'}`}
        >
          {conversion ? (
            <div className='flex items-center justify-center'>
              <img
                src={getStaticAssetPath('images/v2/you-re-in.png')}
                alt={`You're in`}
                className='w-15 h-15'
              />
              <div className='text-green-001 text-3.5xl font-bold'>
                Ok, you’re in!
              </div>
            </div>
          ) : (
            <div className='text-tertiary text-center text-3.5xl pt-4'>
              Join the Fun!
            </div>
          )}
          <div className='w-80 lg:w-100 h-full flex flex-col justify-between items-center'>
            <Formik
              initialValues={{ username: initialFirstName ?? '' }}
              validate={validate}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, errors }) => (
                <Form className='w-full h-full flex flex-col items-center justify-between'>
                  <div className='w-full flex flex-col'>
                    <label className='w-full'>
                      <div className='font-bold'>First Name</div>
                      <Field
                        className={`${
                          errors?.username ? 'field-error' : 'field'
                        } w-full h-12.5 mb-1`}
                        name='username'
                        id='username'
                        maxLength={26}
                        placeholder='First Name'
                        autoFocus
                        autoComplete='one-time-code'
                      />
                    </label>
                    <div className='pl-1 h-4 text-3xs text-red-005'>
                      {errors.username || error?.msg}
                    </div>
                  </div>

                  <button
                    data-testid='guest-login-next-btn'
                    type='submit'
                    className='btn-primary w-full h-12.5 font-bold'
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                  >
                    Continue
                  </button>
                  {conversion && (
                    <div className='text-tertiary text-center mt-5 text-sms xl:text-base'>
                      After you’re done playing, claim your free account so you
                      can return any time!
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
          <div className='mt-2 flex flex-col items-center justify-center gap-1'>
            <LegalDisclaimer text='By joining as a guest user' />
            <button
              type='button'
              onClick={handleLoginWithPassword}
              className='text-sms font-medium text-icon-gray'
            >
              Login with LP Account
            </button>
          </div>
        </div>
      </div>
    </LoginModalLayoutV2>
  );
};

function OrgLoginHeader(props: { org: PublicOrganization | null }) {
  if (!props.org) return null;

  const logoUrl = MediaUtils.PickMediaUrl(props.org.logo, {
    priority: [MediaFormatVersion.MD],
  });

  return (
    <div className='flex flex-col items-center justify-center gap-5 absolute -top-5 transform-gpu -translate-y-full w-full'>
      {logoUrl ? (
        <img src={logoUrl} className='w-25 h-25 rounded-xl' alt='logo' />
      ) : null}
      <div className='text-2xl font-bold text-center lg:whitespace-nowrap'>
        {props.org.name}
      </div>
    </div>
  );
}

export const GuestLoginModalWithOrg = (props: {
  asOrg: DtoPublicOrganization;
  setStep: (i: number) => void;
}): JSX.Element | null => {
  const { asOrg, setStep } = props;
  const [error, setError] = useState<APIError | null>(null);
  const postLogin = usePostLogin();
  const { data: color } = useLogoBrandColor(fromDTOPublicOrganization(asOrg));

  const validate = (values: { username: string }) => {
    const errors: { username?: string } = {};
    if (!values.username) {
      errors.username = 'First name is required';
    } else if (values.username.length < 3) {
      errors.username = 'First name must be at least 3 characters';
    } else if (values.username.length > 25) {
      errors.username = 'Max character count reached';
    }

    return errors;
  };

  const onSubmit = async (values: FormikValues, actions: FormikValues) => {
    try {
      const resp = await apiService.auth.guestLogin({
        username: values.username,
      });
      postLogin(resp.data);
    } catch (error: UnassertedUnknown) {
      setError(error);
    }
    actions.setSubmitting(false);
    setStep(1);
  };

  if (!color) return null;

  const backgroundColor = color.cssHSLAWithLightness(0.3);

  return (
    <div
      className='w-full h-full relative flex items-center justify-center'
      style={{
        backgroundColor,
      }}
    >
      <div className='relative text-white transform translate-y-[10%] w-full flex justify-center'>
        <OrgLoginHeader org={fromDTOPublicOrganization(asOrg)} />
        <div
          className={`
            w-max h-full flex flex-col items-center bg-black
            bg-opacity-80 border border-secondary rounded-2.25xl
            py-5 lg:py-10 px-5 lg:px-8 gap-5
          `}
        >
          <div className='w-80 lg:w-100 h-full flex flex-col justify-between items-center'>
            <Formik
              initialValues={{ username: '' }}
              validate={validate}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, errors }) => (
                <Form className='w-full h-full flex flex-col items-center justify-between'>
                  <div className='w-full flex flex-col'>
                    <label className='w-full flex flex-col gap-2.5'>
                      <div className='font-bold'>
                        Enter your name to get started
                      </div>
                      <Field
                        className={`${
                          errors?.username ? 'field-error' : 'field'
                        } w-full h-12.5 mb-1`}
                        name='username'
                        id='username'
                        maxLength={26}
                        placeholder='First Name'
                        autoFocus
                        autoComplete='one-time-code'
                      />
                    </label>
                    <div className='pl-1 h-8 text-3xs text-red-005'>
                      {errors.username || error?.msg}
                    </div>
                  </div>

                  <button
                    data-testid='guest-login-next-btn'
                    type='submit'
                    className='btn-primary w-full h-12.5 font-bold'
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                  >
                    Continue
                  </button>
                </Form>
              )}
            </Formik>
          </div>
          <div className='mt-2 flex flex-col items-center justify-center gap-1'>
            <LegalDisclaimer text='By continuing' downplay />
          </div>
        </div>
      </div>
    </div>
  );
};
